<template>
  <div>Board Error</div>
</template>

<script>
export default {
    name : 'BoardError',
}
</script>

<style>

</style>